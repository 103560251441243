<template>
    <div>
        <template v-if="!loading">
            <div
                v-if="showTitle"
                class="flex items-start justify-between bg-gray-50 border-b border-gray-100 rounded-t-md p-6"
            >
                <div class="flex flex-col">
                    <h2 class="text-lg font-semibold">Category Breakdown</h2>
                    <p class="text-sm text-gray-500">
                        This report gives insight into Prep exam performance
                        broken down by category and subcategory.
                    </p>
                </div>
                <div>
                    <button
                        v-if="showClose"
                        type="button"
                        @click="$emit('closeCategoryBreakdown')"
                    >
                        <SvgIcon icon="close" size="sm" weight="bold" />
                    </button>
                </div>
            </div>
            <template v-if="pBank">
                <div class="grid grid-cols-2 md:grid-cols-4">
                    <button
                        v-for="category in parents"
                        class="flex flex-col items-center justify-center border-r border-gray-100 last:border-r-0 p-6"
                        @click="showDetail(category.id)"
                    >
                        <div
                            class="relative flex items-start justify-center w-full h-full max-w-64 max-h-64"
                        >
                            <PrepCategoryReportDonut
                                :name="category.category"
                                :correct="
                                    $options.methods.optional(
                                        categories[category.id],
                                        'questions_correct'
                                    )
                                "
                                :total="
                                    $options.methods.optional(
                                        categories[category.id],
                                        'questions_total'
                                    )
                                "
                            />
                        </div>
                        <div
                            class="text-lg md:text-xl font-medium md:font-normal font-light space-y-2 mt-6"
                        >
                            <div class="flex items-center justify-center">
                                {{ category.category }}
                            </div>
                            <div class="flex items-center justify-center">
                                <div
                                    v-if="
                                        $options.methods.optional(
                                            categories[category.id],
                                            'questions_total'
                                        ) > 0
                                    "
                                    class="inline-flex items-center justify-center bg-gray-100 border border-gray-200 text-sm leading-8 rounded-md space-x-1 px-4"
                                >
                                    <span
                                        v-text="
                                            $options.methods.optional(
                                                categories[category.id],
                                                'questions_correct'
                                            )
                                        "
                                    />
                                    <span>/</span>
                                    <span
                                        v-text="
                                            $options.methods.optional(
                                                categories[category.id],
                                                'questions_total'
                                            )
                                        "
                                    />
                                    <span class="font-medium pl-1"
                                        >correct</span
                                    >
                                </div>
                                <div
                                    v-else
                                    class="inline-flex items-center justify-center bg-gray-100 border border-gray-200 text-sm leading-8 rounded-md space-x-1 px-4"
                                >
                                    No questions
                                </div>
                            </div>
                        </div>
                    </button>
                </div>
            </template>
            <template v-else>
                <div class="flex items-center justify-center h-40">
                    For detailed reporting, please choose a question bank
                </div>
            </template>
            <div
                v-if="showBankDropdown || showPulseLink"
                class="flex items-center justify-between bg-gray-50 border-t border-gray-100 rounded-b-md"
            >
                <div class="p-6">
                    <div class="flex flex-col">
                        <label
                            class="font-semibold text-xs uppercase leading-6 ml-2"
                        >
                            Question bank
                        </label>
                        <div>
                            <SelectDropdown v-model="pBank" theme="flat">
                                <option value="2024 Radiography" selected>
                                    2024 Radiography (R)
                                </option>
                                <option value="2024 Radiography Exam 1" selected>
                                    2024 ARRT® Radiography Exam 1
                                </option>
                                <option value="2024 Radiography Exam 2" selected>
                                    2024 ARRT® Radiography Exam 2
                                </option>
                                <option value="2024 Limited Scope of Practice Radiography" selected>
                                    2024 Limited Scope of Practice Radiography
                                </option>
                                <option value="ARRT® Limited Scope of Practice Radiography (Core) Exam 1" selected>
                                    ARRT® Limited Scope of Practice Radiography (Core) Exam 1
                                </option>
                                <option value="ARRT® Limited Scope of Practice Radiography (Core) Exam 2" selected>
                                    ARRT® Limited Scope of Practice Radiography (Core) Exam 2
                                </option>
                                <option value="ARRT® Limited Scope of Practice Radiography Chest Procedures Exam" selected>
                                    ARRT® Limited Scope of Practice Radiography Chest Procedures Exam
                                </option>
                                <option value="ARRT® Limited Scope of Practice Radiography Extremities Procedures Exam" selected>
                                    ARRT® Limited Scope of Practice Radiography Extremities Procedures Exam
                                </option>
                                <option value="ARRT® Limited Scope of Practice Radiography Podiatric Procedures Exam" selected>
                                    ARRT® Limited Scope of Practice Radiography Podiatric Procedures Exam
                                </option>
                                <option value="ARRT® Limited Scope of Practice Radiography Skull/Sinuses Procedures Exam" selected>
                                    ARRT® Limited Scope of Practice Radiography Skull/Sinuses Procedures Exam
                                </option>
                                <option value="ARRT® Limited Scope of Practice Radiography Spine Procedures Exam" selected>
                                    ARRT® Limited Scope of Practice Radiography Spine Procedures Exam
                                </option>
                                <option value="CT">ARRT Computed Tomography (CT) Bank</option>
                                <option value="CT - Simulated Exam 1">
                                    ARRT® Computed Tomography Exam 1
                                </option>
                                <option value="CT - Simulated Exam 2">
                                    ARRT® Computed Tomography Exam 2
                                </option>
                                <option value="Magnetic Resonance Imaging">
                                    Magnetic Resonance Imaging (MRI)
                                </option>
                                <option value="Magnetic Resonance Imaging Exam 1">
                                    ARRT® Magnetic Resonance Imaging Exam 1
                                </option>
                                <option value="Magnetic Resonance Imaging Exam 2">
                                    ARRT® Magnetic Resonance Imaging Exam 2
                                </option>
                                <option value="California Radiography Supervisor and Operator Examination">
                                    California Radiography Supervisor and Operator Examination
                                </option>
                                <option value="ARRT® California Radiography Supervisor and Operator Examination Exam 1">
                                    ARRT® California Radiography Supervisor and Operator Examination Exam 1
                                </option>
                                <option value="ARRT® California Radiography Supervisor and Operator Examination Exam 2">
                                    ARRT® California Radiography Supervisor and Operator Examination Exam 2
                                </option>
                                <option value="Medication Aide Certification 2024">
                                    Certified Medication Aide
                                </option>
                                <option value="MACE Mock Exam 1">
                                    MACE®  Medication Aide Exam 1
                                </option>
                                <option value="MACE Mock Exam 2">
                                    MACE® Medication Aide Exam 2
                                </option>
                                <option value="Kentucky Medication Aide Exam 1">
                                    Kentucky Medication Aide Exam 1
                                </option>
                                <option value="Kentucky Medication Aide Exam 2">
                                    Kentucky Medication Aide Exam 2
                                </option>
                                <option value="Radiography" selected>
                                    2020 Radiography (R) (Legacy)
                                </option>
                                <option value="LMRT">Limited Radiography (LMRT) Bank</option>
                            </SelectDropdown>
                        </div>
                    </div>
                </div>
                <div class="p-6">
                    <a
                        v-if="showPulseLink"
                        class="text-sm underline"
                        :href="
                            '/pulse/explore/prep?subject=' +
                            subject +
                            '&period=1w'
                        "
                        target="_blank"
                    >
                        View more in Pulse
                    </a>
                </div>
            </div>
        </template>
        <PrepCategoryReportModal
            :active="showModal"
            :category="detailCategory"
            @close="hideModal"
        />
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState } from "vuex";


export default {
    props: {
        bank: String,
        exam: Number,
        period: String,
        rows: Array,
        showBankDropdown: Boolean,
        showClose: Boolean,
        showPulseLink: Boolean,
        showTitle: Boolean,
        subject: String,
    },
    data(): any {
        return {
            pBank: "Radiography",
            banks: {},
            categories: [],
            detailCategoryId: null,
            loading: true,
            showModal: false,
        };
    },
    computed: {
        ...mapState(["user"]),
        context() {
            return (
                "?subject=" +
                this.subject +
                "&period=" +
                this.period +
                "&bank=" +
                this.bank
            );
        },
        detailCategory() {
            if (!this.detailCategoryId) {
                return {};
            }

            let structure = this.banks[this.pBank];
            let categories = this.categories;

            return {
                name: structure.filter((o) => o.id == this.detailCategoryId)[0]
                    .category,
                rows: structure
                    .filter((o) => o.parent_id == this.detailCategoryId)
                    .map((o) => {
                        return {
                            name: o.category,
                            questions_correct: this.$options.methods.optional(
                                categories[o.id],
                                "questions_correct"
                            ),
                            questions_total: this.$options.methods.optional(
                                categories[o.id],
                                "questions_total"
                            ),
                            children: structure
                                .filter((a) => a.parent_id == o.id)
                                .map((a) => {
                                    return {
                                        name: a.category,
                                        questions_correct:
                                            this.$options.methods.optional(
                                                categories[a.id],
                                                "questions_correct"
                                            ),
                                        questions_total:
                                            this.$options.methods.optional(
                                                categories[a.id],
                                                "questions_total"
                                            ),
                                    };
                                }),
                        };
                    }),
            };
        },
        hasCategories() {
            return Object.keys(this.categories).length > 0;
        },
        rowIds() {
            return this.exam
                ? [parseInt(this.exam)]
                : this.rows.map((o) => o.id);
        },
        activeBank() {
            return Object.keys(this.banks).length > 0
                ? this.banks[this.pBank]
                : null;
        },
        parents() {
            return this.activeBank && this.hasCategories
                ? this.activeBank.filter((o) => o.parent_id == null)
                : [];
        },
    },
    methods: {
        getData() {
            this.loading = true;
            this.categories = [];
            axios
                .post("/api/pulse/prep-categories" + this.context, {
                    exams: this.rowIds,
                })
                .then((response) => {
                    this.categories = response.data;
                    this.loading = false;
                });
        },
        getStructure() {
            axios.get("/api/pulse/prep-category-structure").then((response) => {
                this.banks = response.data;
            });
        },
        hideModal() {
            this.showModal = false;
            this.detailCategoryId = null;
        },
        showDetail(categoryId) {
            this.detailCategoryId = categoryId;
            this.showModal = true;
        },
    },
    mounted() {
        this.getStructure();
        this.getData();
        this.pBank = this.bank || this.pBank;
    },
    watch: {
        pBank(value) {
            this.$emit("updateBank", value);
        },
    },
};
</script>
