import z from "zod";

const planValidation = z.enum([
	"student",
	"professional",
	"ce",
	"rtbc",
	"ctbc",
]);
const frequencyValidation = z.enum(["1m", "3m", "6m", "1y", "2y"]);
export type PlanKeys = z.infer<typeof planValidation>;
export type FrequencyKeys = z.infer<typeof frequencyValidation>;

export const planLabels = {
	student: "Training & Exam Prep",
	professional: "Cross-Training",
	ce: "Continuing Education",
};

export const frequencyLabels = {
	"1m": "Monthly",
	"3m": "Quarterly - 3 months",
	"6m": "Semiyearly - 6 months",
	"1y": "Yearly",
	"2y": "Bi-Yearly",
};

export const frequencies = {
	"1m": {
		key: "1m",
		name: "Pay Monthly",
		per: "per month",
		alt: "monthly",
	},
	"3m": {
		key: "3m",
		name: "Pay Quarterly",
		per: "per quarter",
		alt: "quarterly",
	},
	"6m": {
		key: "6m",
		name: "Pay Semiyearly",
		per: "per 6 months",
		alt: "semi-yearly",
	},
	"1y": {
		key: "1y",
		name: "Pay Yearly",
		per: "per year",
		alt: "yearly",
	},
	"2y": {
		key: "2y",
		name: "Pay Bi-Yearly",
		per: "per 2 years",
		alt: "bi-yearly",
	},
} as const;

export type Feature = {
	type: "basic" | "missing";
	description: string;
	bold?: boolean;
};

export interface UpcomingInvoice {
	object: "invoice";
	account_country: string;
	account_name: string;
	account_tax_ids?: string[];
	amount_due: number;
	amount_paid: number;
	amount_remaining: number;
	application?: any;
	application_fee_amount?: any;
	attempt_count: number;
	attempted: boolean;
	automatic_tax: {
		enabled: boolean;
		status: string;
	};
	billing_reason: "upcoming";
	charge?: any;
	collection_method: "charge_automatically";
	created: number;
	currency: string;
	custom_fields?: any;
	customer?: any;
	customer_address: {
		city: string;
		country: string;
		line1: string;
		line2?: string;
		postal_code: string;
		state: string;
	};
	customer_email?: string;
	customer_name?: string;
	customer_phone?: string;
	customer_shipping?: any;
	customer_tax_exempt: "none";
	customer_tax_ids?: string[];
	default_payment_method?: any;
	default_source?: any;
	default_tax_rates?: any[];
	description?: string;
	discount: {
		id: string;
		object: "discount";
		checkout_session?: any;
		coupon: {
			id: string;
			object: "coupon";
			amount_off?: any;
			created: number;
			currency?: any;
			duration: "forever";
			duration_in_months?: any;
			livemode: boolean;
			max_redemptions?: any;
			metadata: any[];
			name: string;
			percent_off: number;
			redeem_by?: any;
			times_redeemed: number;
			valid: boolean;
		};
		customer: string;
		end?: any;
		invoice?: any;
		invoice_item?: any;
		promotion_code?: any;
		start: number;
		subscription: string;
	};
	discounts: string[];
	due_date?: any;
	ending_balance: number;
	footer?: string;
	from_invoice?: any;
	last_finalization_error?: any;
	latest_revision?: any;
	lines: Lines;
	livemode: false;
	metadata: any[];
	next_payment_attempt?: any;
	number?: any;
	on_behalf_of?: any;
	paid: boolean;
	paid_out_of_band: boolean;
	payment_intent?: any;
	payment_settings: {
		default_mandate?: any;
		payment_method_options?: any;
		payment_method_types?: any;
	};
	period_end: number;
	period_start: number;
	post_payment_credit_notes_amount: number;
	pre_payment_credit_notes_amount: number;
	quote?: any;
	receipt_number?: any;
	rendering_options?: any;
	starting_balance: number;
	statement_descriptor?: any;
	status: "draft";
	status_transitions: {
		finalized_at?: any;
		marked_uncollectible_at?: any;
		paid_at?: any;
		voided_at?: any;
	};
	subscription: string;
	subscription_proration_date: number;
	subtotal: number;
	subtotal_excluding_tax: number;
	tax?: any;
	test_clock?: any;
	total: number;
	total_discount_amounts: {
		amount: number;
		discount: string;
	}[];
	total_excluding_tax: number;
	total_tax_amounts: any[];
	transfer_data?: any;
	webhooks_delivered_at?: any;
}

interface Lines {
	object: string;
	data: LineItem[];
	has_more: boolean;
	total_count: number;
	url: string;
}

interface LineItem {
	id: string;
	object: string;
	amount: number;
	amount_excluding_tax: number;
	currency: string;
	description: string;
	discountable: boolean;
	discounts: any[];
	livemode: boolean;
	metadata: any[];
	period: Period;
	plan: StripePlan;
	price: Price;
	proration: boolean;
	proration_details: ProrationDetails;
	quantity: number;
	subscription: string;
	subscription_item: string;
	tax_amounts: any[];
	tax_rates: any[];
	type: string;
	unit_amount_excluding_tax: string;
}

interface Period {
	end: number;
	start: number;
}

interface StripePlan {
	id: string;
	object: string;
	active: boolean;
	aggregate_usage: any;
	amount: number;
	amount_decimal: string;
	billing_scheme: string;
	created: number;
	currency: string;
	interval: string;
	interval_count: number;
	livemode: boolean;
	metadata: any[];
	nickname: any;
	product: string;
	tiers_mode: any;
	transform_usage: any;
	trial_period_days: any;
	usage_type: string;
}

interface Price {
	id: string;
	object: string;
	active: boolean;
	billing_scheme: string;
	created: number;
	currency: string;
	custom_unit_amount: any;
	livemode: boolean;
	lookup_key: any;
	metadata: any[];
	nickname: any;
	product: string;
	recurring: Recurring;
	tax_behavior: string;
	tiers_mode: any;
	transform_quantity: any;
	type: string;
	unit_amount: number;
	unit_amount_decimal: string;
}

interface Recurring {
	aggregate_usage: any;
	interval: string;
	interval_count: number;
	trial_period_days: any;
	usage_type: string;
}

interface ProrationDetails {
	credited_items: any;
}
