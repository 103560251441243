<template>
    <alert-banner type="warning" showIcon floating class="my-8">
        Starting December 31, 2024, the
        <strong>2020 ARRT® Radiography (R)</strong> and <strong>2020 ARRT® Limited Radiography (LMRT)</strong> question banks will no longer
        be available. Please use the new and improved
        <strong>2024 ARRT® Radiography (R)</strong> and <strong>2024 ARRT® Limited Scope of Practice Radiography</strong> question banks.
    </alert-banner>
    <div>
        <div class="bg-white rounded-xl shadow">
            <template v-if="!assignment">
                <div
                    class="border-b border-gray-100 bg-gray-50 py-4 px-4 sm:px-8 rounded-t-xl"
                >
                    <div class="flex flex-wrap items-center justify-between">
                        <h3
                            class="font-medium text-base sm:text-lg leading-8 sm:leading-12"
                        >
                            Assignment type
                        </h3>
                    </div>
                </div>
                <div class="flex flex-wrap">
                    <div
                        class="border-b-2 md:border-r md:border-b-0 border-gray-100 w-full md:w-1/3"
                    >
                        <div class="border-b border-gray-100 p-4">
                            <button
                                type="button"
                                class="flex items-center justify-between rounded-lg p-4 w-full"
                                :class="{
                                    'bg-gradient-to-tr from-orange-50 to-red-100':
                                        type == 'custom',
                                }"
                                @click="changeType('custom')"
                            >
                                <div class="flex items-center space-x-8">
                                    <div
                                        class="flex items-center justify-center"
                                    >
                                        <div
                                            class="flex items-center justify-center rounded-full w-16 h-16"
                                            :class="{
                                                'bg-white': type == 'custom',
                                                'bg-gradient-to-tr from-orange-50 to-red-100':
                                                    type != 'custom',
                                            }"
                                        >
                                            <SvgIcon
                                                icon="blueprint"
                                                size="lg"
                                                weight="light"
                                            />
                                        </div>
                                    </div>
                                    <div class="text-left">
                                        <div class="font-medium">
                                            Saved Exam
                                        </div>
                                        <div
                                            class="text-gray-600 text-sm leading-4"
                                        >
                                            Assign an exam from your Exam
                                            Library
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="flex flex-shrink-0 justify-end w-12"
                                >
                                    <SvgIcon
                                        v-if="type == 'custom'"
                                        icon="chevron-right"
                                        size="lg"
                                        weight="thin"
                                    />
                                </div>
                            </button>
                        </div>
                        <div class="border-b border-gray-100 p-4">
                            <button
                                type="button"
                                class="flex items-center justify-between rounded-lg p-4 w-full"
                                :class="{
                                    'bg-gradient-to-tr from-green-50 to-yellow-100':
                                        type == 'simulator',
                                }"
                                @click="changeType('simulator')"
                            >
                                <div class="flex items-center space-x-8">
                                    <div
                                        class="flex items-center justify-center"
                                    >
                                        <div
                                            class="flex items-center justify-center rounded-full w-16 h-16"
                                            :class="{
                                                'bg-white': type == 'simulator',
                                                'bg-gradient-to-tr from-green-50 to-yellow-100':
                                                    type != 'simulator',
                                            }"
                                        >
                                            <SvgIcon
                                                icon="write"
                                                size="lg"
                                                weight="light"
                                            />
                                        </div>
                                    </div>
                                    <div class="text-left">
                                        <div class="font-medium">
                                            Exam Simulator
                                        </div>
                                        <div
                                            class="text-gray-600 text-sm leading-4"
                                        >
                                            Assign a mock registry exam
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="flex flex-shrink-0 justify-end w-12"
                                >
                                    <SvgIcon
                                        v-if="type == 'simulator'"
                                        icon="chevron-right"
                                        size="lg"
                                        weight="thin"
                                    />
                                </div>
                            </button>
                        </div>
                        <div class="p-4">
                            <button
                                type="button"
                                class="flex items-center justify-between rounded-lg p-4 w-full"
                                :class="{
                                    'bg-gradient-to-tr from-blue-50 to-cyan-100':
                                        type == 'quick',
                                }"
                                @click="changeType('quick')"
                            >
                                <div class="flex items-center space-x-8">
                                    <div
                                        class="flex items-center justify-center"
                                    >
                                        <div
                                            class="flex items-center justify-center rounded-full w-16 h-16"
                                            :class="{
                                                'bg-white': type == 'quick',
                                                'bg-gradient-to-tr from-blue-50 to-cyan-100':
                                                    type != 'quick',
                                            }"
                                        >
                                            <SvgIcon
                                                icon="lightning"
                                                size="lg"
                                                weight="light"
                                            />
                                        </div>
                                    </div>
                                    <div class="text-left">
                                        <div class="font-medium">
                                            Quick Exam
                                        </div>
                                        <div
                                            class="text-gray-600 text-sm leading-4"
                                        >
                                            Assign an untimed exam of random
                                            questions
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="flex flex-shrink-0 justify-end w-12"
                                >
                                    <SvgIcon
                                        v-if="type == 'quick'"
                                        icon="chevron-right"
                                        size="lg"
                                        weight="thin"
                                    />
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="w-full md:w-2/3">
                        <div class="p-8">
                            <template v-if="type == 'custom'">
                                <div class="mb-8">
                                    <div
                                        class="flex items-center border-b border-gray-100 space-x-3 mb-4 pb-4"
                                    >
                                        <SvgIcon icon="blueprint" size="base" />
                                        <h2 class="text-xl font-medium">
                                            Saved exam
                                        </h2>
                                    </div>
                                    <p>
                                        Choose an exam to assign from the saved
                                        exams in your
                                        <a class="cta" href="/prep/exam-library"
                                            >Exam Library</a
                                        >:
                                    </p>
                                </div>
                                <div v-if="exams.length > 0">
                                    <label
                                        class="block font-bold text-xs uppercase mb-1 ml-2"
                                        >Saved Exams</label
                                    >
                                    <SelectDropdown
                                        v-model="customExam"
                                        theme="flat"
                                    >
                                        <option value="">
                                            Choose a saved exam
                                        </option>
                                        <option
                                            v-for="exam in exams"
                                            :value="exam.uuid"
                                        >
                                            {{ exam.name }}
                                        </option>
                                    </SelectDropdown>
                                </div>
                                <div v-else>
                                    <div
                                        class="flex items-center justify-between bg-gradient-to-tr from-cyan-50 to-blue-50 text-sm rounded-xl p-4"
                                    >
                                        <div
                                            class="flex items-center space-x-2"
                                        >
                                            <SvgIcon
                                                icon="info"
                                                size="sm"
                                                weight="medium"
                                            />
                                            <p>Your Exam Library is empty!</p>
                                        </div>
                                        <a
                                            class="small basic primary button"
                                            href="/prep/exam-library/new?ref=assignments"
                                            >Create a new exam</a
                                        >
                                    </div>
                                </div>
                            </template>
                            <template v-if="type == 'simulator'">
                                <div class="mb-8">
                                    <div
                                        class="flex items-center border-b border-gray-100 space-x-3 mb-4 pb-4"
                                    >
                                        <SvgIcon icon="write" size="base" />
                                        <h2 class="text-xl font-medium">
                                            Exam Simulator
                                        </h2>
                                    </div>
                                    <ul class="list-disc list-inside">
                                        <p>
                                            Provide your learners a true registry exam experience. Category weighting, exam length, and time limit are modeled after credential exam content specifications.
                                        </p>
                                    </ul>
                                </div>
                                <div class="mb-8">
                                    <label
                                        class="block font-bold text-xs uppercase mb-1 ml-2"
                                        >Question Bank</label
                                    >
                                    <SelectDropdown v-model="bank" theme="flat">
                                        <option value="">
                                            Choose a question bank
                                        </option>
                                        <option value="2024-radiography-exam-1">2024 ARRT® Radiography Exam 1</option>
                                        <option value="2024-radiography-exam-2">2024 ARRT® Radiography Exam 2</option>
                                        <option value="arrt-limited-scope-of-practice-radiography-core-exam-1">ARRT® Limited Scope of Practice Radiography (Core) Exam 1</option>
                                        <option value="arrt-limited-scope-of-practice-radiography-core-exam-2">ARRT® Limited Scope of Practice Radiography (Core) Exam 2</option>
                                        <option value="arrt-limited-scope-of-practice-radiography-extremities-procedures-exam">ARRT® Limited Scope of Practice Radiography Extremities Procedures Exam</option>
                                        <option value="arrt-limited-scope-of-practice-radiography-podiatric-procedures-exam">ARRT® Limited Scope of Practice Radiography Podiatric Procedures Exam</option>
                                        <option value="arrt-limited-scope-of-practice-radiography-skullsinuses-procedures-exam">ARRT® Limited Scope of Practice Radiography Skull/Sinuses Procedures Exam</option>
                                        <option value="arrt-limited-scope-of-practice-radiography-spine-procedures-exam">ARRT® Limited Scope of Practice Radiography Spine Procedures Exam</option>
                                        <option value="arrt-limited-scope-of-practice-radiography-chest-procedures-exam">ARRT® Limited Scope of Practice Radiography Chest Procedures Exam</option>
                                        <option value="ct-simulated-1">
                                            ARRT® Computed Tomography Exam 1
                                        </option>
                                        <option value="ct-simulated-2">
                                            ARRT® Computed Tomography Exam 2
                                        </option>
                                        <option value="magnetic-resonance-imaging-exam-1">
                                            ARRT® Magnetic Resonance Imaging Exam 1
                                        </option>
                                        <option value="magnetic-resonance-imaging-exam-2">
                                            ARRT® Magnetic Resonance Imaging Exam 2
                                        </option>
                                        <option value="arrt-california-radiography-supervisor-and-operator-examination-exam-1">ARRT® California Radiography Supervisor and Operator Examination Exam 1</option>
                                        <option value="arrt-california-radiography-supervisor-and-operator-examination-exam-2">ARRT® California Radiography Supervisor and Operator Examination Exam 2</option>
                                        <option value="mace-mock-exam-1">MACE® Medication Aide Exam 1</option>
                                        <option value="mace-mock-exam-2">MACE® Medication Aide Exam 2</option>
                                        <option value="kentucky-medication-aide-exam-1">Kentucky Medication Aide Exam 1</option>
                                        <option value="kentucky-medication-aide-exam-2">Kentucky Medication Aide Exam 2</option>
                                        <option value="Radiography">
                                            2020 ARRT® Radiography Exam (legacy)
                                        </option>
                                        <option value="LMRT">2020 Limited Radiography (LMRT)</option>
                                    </SelectDropdown>
                                </div>
                                <div class="flex items-start space-x-4">
                                    <div>
                                        <label
                                            class="block font-bold text-xs uppercase mb-1 ml-2"
                                            >Access</label
                                        >
                                        <SelectDropdown
                                            v-model="access"
                                            theme="flat"
                                            variant="access"
                                        >
                                            <option
                                                value="reserved"
                                                v-if="!banksWithoutReserve.includes(bank)"
                                            >
                                                Reserved questions
                                            </option>
                                            <option value="open">
                                                Open questions
                                            </option>
                                        </SelectDropdown>
                                    </div>
                                    <div v-if="access == 'reserved'">
                                        <label
                                            class="block font-bold text-xs uppercase mb-1 ml-2"
                                            >&nbsp;</label
                                        >
                                        <div class="text-sm leading-8 p-2">
                                            <strong>Note:</strong> Feedback is
                                            not provided for
                                            <span
                                                class="inline-block bg-gradient-to-tr from-indigo-50 to-blue-100 text-xs uppercase font-semibold leading-6 rounded-lg px-2"
                                                >reserved</span
                                            >
                                            questions
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="type == 'quick'">
                                <div class="mb-8">
                                    <div
                                        class="flex items-center border-b border-gray-100 space-x-3 mb-4 pb-4"
                                    >
                                        <SvgIcon icon="lightning" size="base" />
                                        <h2 class="text-xl font-medium">
                                            Quick exam
                                        </h2>
                                    </div>
                                    <p class="mb-4">
                                        Used primarily for practice, quick exams
                                        are untimed and the questions are
                                        randomly-generated.
                                    </p>
                                </div>
                                <div class="flex items-center space-x-8 mb-8">
                                    <div>
                                        <label
                                            class="block font-bold text-xs uppercase mb-1 ml-2"
                                            >Question Bank</label
                                        >
                                        <SelectDropdown
                                            v-model="bank"
                                            theme="flat"
                                        >
                                            <option value="">
                                                Choose a question bank
                                            </option>
                                            <option value="2024 Radiography" selected>2024 Radiography (R)</option>
                                            <option value="2024 Limited Scope of Practice Radiography">2024 Limited Scope of Practice Radiography</option>
                                            <option value="CT">Computed Tomography (CT)</option>
                                            <option value="Magnetic Resonance Imaging">Magnetic Resonance Imaging (MRI)</option>
                                            <option value="California Radiography Supervisor and Operator Examination">California Radiography Supervisor and Operator Examination</option>
                                            <option value="Medication Aide Certification 2024">Certified Medication Aide</option>
                                            <option value="Radiography">
                                                2020 Radiography (R)
                                            </option>
                                            <option value="LMRT">2020 Limited Radiography (LMRT)</option>
                                        </SelectDropdown>
                                    </div>
                                    <div>
                                        <label
                                            class="block font-bold text-xs uppercase mb-1 ml-2"
                                            >Exam length</label
                                        >
                                        <SelectDropdown
                                            v-model="length"
                                            theme="flat"
                                        >
                                            <option value="">
                                                Choose a # of questions
                                            </option>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                        </SelectDropdown>
                                    </div>
                                </div>
                                <div class="flex items-start space-x-4">
                                    <div>
                                        <label
                                            class="block font-bold text-xs uppercase mb-1 ml-2"
                                            >Access</label
                                        >
                                        <SelectDropdown
                                            v-model="access"
                                            theme="flat"
                                            variant="access"
                                        >
                                            <option
                                                value="reserved"
                                                v-if="!banksWithoutReserve.includes(bank)"
                                            >
                                                Reserved questions
                                            </option>
                                            <option value="open">
                                                Open questions
                                            </option>
                                        </SelectDropdown>
                                    </div>
                                    <div v-if="access == 'reserved'">
                                        <label
                                            class="block font-bold text-xs uppercase mb-1 ml-2"
                                            >&nbsp;</label
                                        >
                                        <div class="text-sm leading-8 p-2">
                                            <strong>Note:</strong> Feedback is
                                            not provided for
                                            <span
                                                class="inline-block bg-gradient-to-tr from-indigo-50 to-blue-100 text-xs uppercase font-semibold leading-6 rounded-lg px-2"
                                                >reserved</span
                                            >
                                            questions
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <div
                class="border-t border-b border-gray-100 bg-gray-50 p-4 sm:p-8"
            >
                <h3 class="font-medium text-lg">Assignment options</h3>
            </div>
            <div class="flex flex-col p-4 sm:p-8 space-y-4 sm:space-y-8">
                <div class="flex item-start justify-between space-x-8">
                    <div class="flex flex-col space-y-8 flex-grow">
                        <div>
                            <label
                                class="block font-bold text-xs uppercase mb-1 ml-2"
                                for="name"
                                >Assignment name</label
                            >
                            <input
                                class="leading-5 rounded-lg border p-3 w-full"
                                id="name"
                                name="name"
                                v-model="name"
                                required
                            />
                        </div>
                        <div>
                            <label
                                class="block font-bold text-xs uppercase mb-1 ml-2"
                                >Assign to</label
                            >
                            <div class="flex items-center space-x-4">
                                <SelectDropdown
                                    theme="flat"
                                    v-model="assignableType"
                                >
                                    <option value="">Choose one</option>
                                    <option value="group">Group</option>
                                    <option value="learner">Learner</option>
                                </SelectDropdown>
                                <SelectDropdown
                                    v-if="assignableCollection?.length"
                                    theme="flat"
                                    v-model="assignable"
                                >
                                    <option value="">
                                        Choose a {{ assignableType }}
                                    </option>
                                    <template
                                        v-for="resource in assignableCollection"
                                    >
                                        <option
                                            :value="resource.uuid"
                                            v-text="resource.name"
                                        />
                                    </template>
                                </SelectDropdown>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-start space-x-8">
                        <div>
                            <label
                                class="block font-bold text-xs uppercase mb-1 ml-2"
                                >Start date</label
                            >
                            <div class="bg-gray-100 rounded-xl p-2">
                                <v-date-picker
                                    ref="start"
                                    mode="dateTime"
                                    :timezone="user.timezone"
                                    :model-config="{
                                        type: 'string',
                                        mask: 'iso',
                                    }"
                                    v-model="startDate"
                                />
                            </div>
                        </div>
                        <div>
                            <label
                                class="block font-bold text-xs uppercase mb-1 ml-2"
                                >Due date</label
                            >
                            <div class="bg-gray-100 rounded-xl p-2">
                                <v-date-picker
                                    ref="end"
                                    mode="dateTime"
                                    :timezone="user.timezone"
                                    :model-config="{
                                        type: 'string',
                                        mask: 'iso',
                                    }"
                                    v-model="endDate"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="bg-gray-50 border-t border-gray-100 py-2 px-4 sm:py-6 sm:px-8 rounded-b-xl"
            >
                <input type="hidden" name="type" :value="type" />
                <input type="hidden" name="blueprint_id" :value="customExam" />
                <input
                    type="hidden"
                    name="assignable_type"
                    :value="assignableType"
                />
                <input type="hidden" name="assignable" :value="assignable" />
                <input type="hidden" name="start_date" :value="startDate" />
                <input type="hidden" name="end_date" :value="endDate" />
                <input type="hidden" name="access" :value="access" />
                <input type="hidden" name="bank" :value="bank" />
                <input type="hidden" name="length" :value="length" />
                <div class="flex flex-wrap items-center space-x-8">
                    <button
                        v-if="!assignment"
                        class="primary button leading-6 my-2 w-48"
                        type="button"
                        @click="submit"
                    >
                        Create assignment
                    </button>
                    <button
                        v-if="assignment"
                        class="primary button leading-6 my-2 w-48"
                        type="button"
                        @click="submit"
                    >
                        Update assignment
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
	props: {
		assignment: {
			type: Object,
			default: () => {},
		},
		groups: Array,
		exam: String,
		exams: Array,
		learners: Array,
	},
	data() {
		return {
			assignableType: "",
			assignable: "",
			customExam: "",
			name: "",
			type: "custom",
			access: "reserved",
			bank: "",
			length: "",
			startDate: null,
			endDate: null,
			status: "ready",
            banksWithoutReserve: [
                '2024 Radiography',
                'Medication Aide Certification 2024',
                '2024-radiography-exam-1',
                '2024-radiography-exam-2',
                'mace-mock-exam-1',
                'mace-mock-exam-2',
                'kentucky-medication-aide-exam-1',
                'kentucky-medication-aide-exam-2',
                'ct-simulated-1',
                'ct-simulated-2',
                'CT',
                '2024 Limited Scope of Practice Radiography',
                'California Radiography Supervisor and Operator Examination',
                '2024-limited-scope-of-practice-radiography',
                'arrt-california-radiography-supervisor-and-operator-examination-exam-1',
                'arrt-california-radiography-supervisor-and-operator-examination-exam-2',
                'arrt-limited-scope-of-practice-radiography-core-exam-1',
                'arrt-limited-scope-of-practice-radiography-core-exam-2',
                'arrt-limited-scope-of-practice-radiography-chest-procedures-exam',
                'arrt-limited-scope-of-practice-radiography-extremities-procedures-exam',
                'arrt-limited-scope-of-practice-radiography-podiatric-procedures-exam',
                'arrt-limited-scope-of-practice-radiography-skullsinuses-procedures-exam',
                'arrt-limited-scope-of-practice-radiography-spine-procedures-exam',
                'california-radiography-supervisor-and-operator-examination',
                'Magnetic Resonance Imaging',
                'magnetic-resonance-imaging',
                'magnetic-resonance-imaging-exam-1',
                'magnetic-resonance-imaging-exam-2'

            ]
		};
	},
	watch: {
		bank() {
			if (this.banksWithoutReserve.includes(this.bank)) {
				this.access = "open";
			} else {
				this.access = "reserved";
			}
		},
	},
	computed: {
		...mapState(["user"]),
		assignableCollection() {
			if (this.assignableType == "") {
				return [];
			}
			return this.assignableType == "group" ? this.groups : this.learners;
		},
		selectedCustomExam() {
			return this.exams.filter((o) => o.uuid == this.customExam);
		},
		submittable() {
			if (!this.assignment) {
				return (
					this.type != "" &&
					this.name != "" &&
					this.assignable != "" &&
					(this.type != "custom" ||
						(this.type == "custom" && this.customExam != "")) &&
					(this.type != "simulator" ||
						(this.type == "simulator" &&
							this.bank != "" &&
							this.access != "")) &&
					(this.type != "quick" ||
						(this.type == "quick" &&
							this.length != "" &&
							this.bank != "" &&
							this.access != "")) &&
					this.startDate !== null &&
					this.endDate !== null
				);
			}
			return (
				this.name != "" &&
				this.assignable != "" &&
				this.startDate !== null &&
				this.endDate !== null
			);
		},
	},
	methods: {
		changeType(type) {
			this.access = "reserved";
			this.bank = "";
			this.customExam = "";
			this.length = "";
			this.type = type;
		},
		submit() {
			if (this.submittable) {
				setTimeout(() => {
					this.status = "clicked";
					document.getElementById("assignmentForm").submit();
				}, 50);
			} else {
				this.warn();
			}
		},
		warn() {
			let message = 'Please fix the following issues</p><p class="mt-4">';

			if (this.type == "") {
				message = message + "• No exam type selected<br>";
			}

			if (this.type == "custom" && this.customExam == "") {
				message = message + "• No saved exam selected<br>";
			}

			if (
				(this.type == "simulator" || this.type == "quick") &&
				this.bank == ""
			) {
				message = message + "• No question bank selected<br>";
			}

			if (
				(this.type == "simulator" || this.type == "quick") &&
				this.access == ""
			) {
				message = message + "• No access level selected<br>";
			}

			if (this.type == "quick" && this.length == "") {
				message = message + "• No exam length<br>";
			}

			if (this.name == "") {
				message = message + "• Assignment not named<br>";
			}

			if (this.assignable == "") {
				message = message + "• No assignee selected<br>";
			}

			if (this.startDate === null) {
				message = message + "• No start date selected<br>";
			}

			if (this.endDate === null) {
				message = message + "• No due date selected<br>";
			}

			this.$events.$emit("openModal", {
				type: "warning",
				heading: "Hold up!",
				message: message,
			});
		},
	},
	mounted() {
		this.customExam = this.exam || this.customExam;

		if (this.assignment) {
			this.name = this.assignment.name;
			this.startDate = this.assignment.start_date;
			this.endDate = this.assignment.end_date;
			this.assignableType =
				this.assignment.groups.length > 0 ? "group" : "learner";
			this.assignable = this.assignment.assignables[0].uuid;
		}
	},
};
</script>
